// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-recipe-jsx": () => import("./../src/templates/recipe.jsx" /* webpackChunkName: "component---src-templates-recipe-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-datenschutz-jsx": () => import("./../src/pages/datenschutz.jsx" /* webpackChunkName: "component---src-pages-datenschutz-jsx" */),
  "component---src-pages-herkunft-und-qualitaet-jsx": () => import("./../src/pages/herkunft-und-qualitaet.jsx" /* webpackChunkName: "component---src-pages-herkunft-und-qualitaet-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-karriere-arbeiten-bei-uns-jsx": () => import("./../src/pages/karriere/arbeiten-bei-uns.jsx" /* webpackChunkName: "component---src-pages-karriere-arbeiten-bei-uns-jsx" */),
  "component---src-pages-karriere-stellenangebote-und-ausbildungsberufe-jsx": () => import("./../src/pages/karriere/stellenangebote-und-ausbildungsberufe.jsx" /* webpackChunkName: "component---src-pages-karriere-stellenangebote-und-ausbildungsberufe-jsx" */),
  "component---src-pages-marken-und-produkte-jsx": () => import("./../src/pages/marken-und-produkte.jsx" /* webpackChunkName: "component---src-pages-marken-und-produkte-jsx" */),
  "component---src-pages-rezepte-jsx": () => import("./../src/pages/rezepte.jsx" /* webpackChunkName: "component---src-pages-rezepte-jsx" */),
  "component---src-pages-service-und-kontakt-downloads-jsx": () => import("./../src/pages/service-und-kontakt/downloads.jsx" /* webpackChunkName: "component---src-pages-service-und-kontakt-downloads-jsx" */),
  "component---src-pages-service-und-kontakt-faq-jsx": () => import("./../src/pages/service-und-kontakt/faq.jsx" /* webpackChunkName: "component---src-pages-service-und-kontakt-faq-jsx" */),
  "component---src-pages-service-und-kontakt-glossar-jsx": () => import("./../src/pages/service-und-kontakt/glossar.jsx" /* webpackChunkName: "component---src-pages-service-und-kontakt-glossar-jsx" */),
  "component---src-pages-service-und-kontakt-haendlersuche-jsx": () => import("./../src/pages/service-und-kontakt/haendlersuche.jsx" /* webpackChunkName: "component---src-pages-service-und-kontakt-haendlersuche-jsx" */),
  "component---src-pages-service-und-kontakt-kontakt-jsx": () => import("./../src/pages/service-und-kontakt/kontakt.jsx" /* webpackChunkName: "component---src-pages-service-und-kontakt-kontakt-jsx" */),
  "component---src-pages-service-und-kontakt-zutaten-und-allergene-jsx": () => import("./../src/pages/service-und-kontakt/zutaten-und-allergene.jsx" /* webpackChunkName: "component---src-pages-service-und-kontakt-zutaten-und-allergene-jsx" */),
  "component---src-pages-unternehmen-jsx": () => import("./../src/pages/unternehmen.jsx" /* webpackChunkName: "component---src-pages-unternehmen-jsx" */)
}

